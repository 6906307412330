@import "../../../../styles/theme.scss";

.labelAndError {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.error {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 8px;
  color: $error;
}

.errorIcon {
  margin-left: -40px;
  color: $error;
}
