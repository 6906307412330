.Notification {
  cursor: pointer;

  &__card {
    align-items: center;
    align-self: stretch;
    background-color: #fff;
    border: solid 0.5px rgba(9, 28, 55, 0.1);
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    gap: 16px;
    justify-content: flex-start;
    padding: 24px 20px;

    &:hover {
      background-color: #f0f7f5;
      + #readIcon {
        opacity: 1;
      }
    }
  }

  &__createdOn {
    align-self: stretch;
    color: #575a60;
    flex-grow: 0;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.5;
    opacity: 0.5;
    text-align: left;
  }

  &__description {
    color: #575a60;
    font-size: 14px;
    padding: 10px 0px;
  }

  &__icon {
    width: 32px;
  }

  &__iconBox {
    margin: auto;
    padding: 10px;
  }

  &__middle {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 8px;
    justify-content: space-between;
  }

  &__readIcon {
    flex-grow: 0;
    height: 20px;
    object-fit: contain;
    opacity: 0.5;
    width: 20px;
  }

  &__title {
    align-self: stretch;
    color: #091c37;
    flex-grow: 0;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.3;
    text-align: left;
  }

  &__readButton {
    all: unset;
  }
}

.hide {
  opacity: 0;
}

.unread {
  border-left: solid 4px #8cce65;
}
