@import "styles/theme.scss";

.container {
  position: relative;
}

.menu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(9, 28, 55, 0.1);
  display: flex;
  flex-direction: column;
}

.menuItem {
  padding: 10px 20px;
  border-bottom: 1px solid #e6e8eb;
  color: $navy;
  background-color: white;
  transition: background-color 0.2s;

  &:hover {
    background-color: $pale-mint;
    color: $navy;
  }

  &:last-child {
    border-bottom: none;
  }
}

.menuRightAlign {
  text-align: right;
}
