@import "../../../../styles/theme.scss";

.CondensedNavHeader {
  &__bellIcon {
    height: 24px;
    width: 24px;
    cursor: pointer;

    &_Inverted {
      fill: $pale-mint;
    }
  }

  &__navbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  &__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
  }

  &__logo {
    justify-self: center;
  }

  &__hamburger {
    display: flex;
    justify-content: flex-end;
  }

  &__bellIconNotificationAlert {
    display: flex;
    padding-left: 10px;
  }

  &__notificationAlert {
    width: 5px;
    height: 5px;
    flex-grow: 0;
    border-radius: 100px;
    background-color: #85bb65;
  }

  &__searchIcon {
    height: 24px;
    width: auto;

    g {
      transition: stroke 0.3s;
    }

    &_Inverted g {
      stroke: $main;
    }
  }

  &__logo {
    height: 24px;
    width: auto;

    path {
      // transition: fill 0.3s;
      // fill: white;
    }

    &_Inverted {
      path:not(:first-child) {
        fill: white;
      }
    }
  }
}
