@import "../../../styles/theme.scss";

.SidebarResult {
  padding: 12px 0 12px 10px;
  display: flex;

  &:hover {
    background-color: $pale-mint;
    cursor: pointer;
  }

  @media (max-width: $small) {
    // margin-left: 10px;
    flex-direction: column;
    gap: 10px;
  }
}

.imageContainer {
  position: relative;
  max-width: 143px;
  min-width: 143px;
  max-height: 110px;

  @media (max-width: $small) {
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
  }
}

.logoContainer {
  position: absolute;
  top: 25%;
  left: 25%;
  height: 50%;
  width: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foregroundImage {
  filter: brightness(0) invert(1);
  max-width: 100%;
  max-height: 100%;
}

.imageTint {
  position: absolute;
  background-color: rgba(9, 28, 55, 0.8);
  height: 100%;
  width: 100%;
}

.backgroundImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.textContainer {
  margin-left: 23px;
  display: flex;
  flex-direction: column;

  @media (max-width: $small) {
    margin-left: 0;
  }
}

.subTitle {
  font-size: 12px;
  line-height: 1.75;
  margin-bottom: 4px;
}

.source {
  font-size: 12px;
  line-height: 1.75;
  margin-bottom: 4px;
  color: $navy;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  padding-right: 40px;
}
