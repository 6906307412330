@import "../../../styles/theme.scss";

.inputContainer {
  margin-top: 24px;
  margin-bottom: 48px;
  width: 100%;
  padding: 0 70px;

  @media (max-width: $small) {
    padding: 0 40px;
  }
}

.input {
  width: 100%;
}

.icon {
  width: 24px;
  margin-right: 12px;
}

.closeContainer {
  text-align: right;
  padding-right: 24px;
  margin-top: 24px;
  width: 100%;
  height: 16px;
}

.contentContainer {
  padding: 0 70px;
  margin-bottom: 48px;

  :last-child {
    border-bottom: none;
  }

  @media (max-width: $small) {
    padding: 0 40px;
  }
}

.sectionHeading {
  line-height: 1.1;
  letter-spacing: -0.24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.brandResult {
  min-height: 55.5px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  margin: 8px 0;

  &:hover {
    background-color: $pale-mint;
    cursor: pointer;
  }

  &_Loading {
    background-color: $pale-mint;
  }

  // @media (max-width: $small) {
  //   padding-left: 20px;
  // }
}

.brandTicker {
  min-width: 100px;
  // line-height: 1.75;
  margin-bottom: 0;
  margin-right: 24px;

  @media (max-width: $small) {
    width: 80px;
    min-width: 80px;
    margin-right: 8px;
  }
}

.brandName {
  line-height: 1.75;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.brandContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 12px 0;
}

.subBrand {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.65;
  margin: 3px 0;
}

.insightContainer {
  border-bottom: 1px solid rgb(87, 90, 96, 0.2);
  padding: 25px 0;

  &:hover {
    background-color: $pale-mint;
    cursor: pointer;
  }

  &_Loading {
    min-height: 90px;
    background-color: $pale-mint;
  }
}

.insightTitle {
  padding: 0 40px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  @media (max-width: $small) {
    padding: 0 20;
  }
}

.disabled {
  pointer-events: none;
}

.SidebarPerkResult {
  margin-left: 30px;
  margin-bottom: 12px;

  &_Loading {
    background-color: $pale-mint;
    min-height: 120px;
  }
}
