@import "../../../../styles/theme.scss";

.Hamburger {
  height: 24px;
  width: 24px;
  cursor: pointer;
  position: relative;

  &:hover .line {
    background-color: $main;
  }

  &_isOpen {
    .line {
      background-color: $main;

      &:nth-child(1) {
        width: 32.5px;
        transform: translateY(-4px) translateX(-2px) rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        width: 32.5px;
        transform: translateY(4px) translateX(-2px) rotate(45deg);
      }
    }
  }
}

.line {
  height: 2px;
  width: 24px;
  transition: background-color 0.3s, width 0.3s, opacity 0.3s, transform 0.3s;
  background-color: $secondary;

  position: absolute;
  right: 0;

  &:nth-child(1) {
    transform-origin: top right;
    top: 4px;
  }
  &:nth-child(2) {
    top: 11.25px;
    width: 13px;
  }
  &:nth-child(3) {
    bottom: 4px;
    transform-origin: bottom right;
    width: 17px;
  }
}
