@import "../../../styles/theme.scss";

$sidebarWidth: 603px;

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;

  z-index: 1001;
  width: $sidebarWidth;
  overflow: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  animation-duration: 0.3s;
  animation-name: slidein;

  @media (max-width: $small) {
    width: 100%;
  }
}

.closeContainer {
  text-align: right;
  padding-right: 24px;
  margin-top: 24px;
  width: 100%;
  height: 16px;
}

@keyframes slidein {
  from {
    right: -$sidebarWidth;

    @media (max-width: $small) {
      right: -100%;
    }
  }
}

.closing {
  animation-duration: 0.3s;
  animation-name: slideout;
}

@keyframes slideout {
  to {
    right: -$sidebarWidth;

    @media (max-width: $small) {
      right: -100%;
    }
  }
}

.hidden {
  display: none;
}
