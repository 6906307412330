@import "../../../../styles/theme.scss";

.CondensedNav {
  &__menuNavWrapper {
    padding: 24px 24px 0;
  }

  &__mobileMenu {
    background-color: $secondary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0vh;
    z-index: 100;
    transition: height 0.3s cubic-bezier(0.06, 0.67, 0.41, 0.9);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
    &__Open {
      height: 100vh;
      overflow: auto;
      // /* mobile viewport bug fix */
      // height: -webkit-fill-available;
    }
  }

  &__linkList {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
  }
  
  &__navLink {
    border-bottom: 1px solid transparentize($main, 0.8);
    color: white;
    padding: 20px calc(0.75rem + 24px);
    font-size: 18px;
    line-height: 1.2;
    font-weight: normal;
    display: inline-flex;
  
    span {
      display: inline-block;
      margin: auto;
      width: 100%;
  
      @media (max-width: $medium) {
        max-width: calc(720px - 24px);
      }
  
      @media (max-width: $small) {
        max-width: calc(540px - 24px);
      }
    }
  
    &:hover {
      color: $main;
    }
  
    &:last-child {
      border-bottom: none;
    }
  }

  &__lowerMenuWrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 32px 24px;
  }
  
  &__mobileSearch {
    width: 100%;
    border: 1px solid transparentize($main, 0.6);
    background-color: transparent;
    padding: 15px 20px;
  
    &::placeholder {
      color: white;
    }
  }
  
  &__joinNow {
    width: 100%;
  
    span {
      width: 100%;
      text-align: center;
    }
  }
  
  
  &__dropdownArrow {
    transition: transform 0.3s ease;
    fill: white;
    transform: rotate(180deg);
    &_open {
      transform: rotate(360deg);
    }
  }
  
  &__dropdown {
    background-color: darken($secondary, 5%);
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  
    &_open {
      max-height: 500px; // Adjust this value based on your needs
      transition: max-height 0.5s ease-in;
    }
  }
  
  &__dropdownItem {
    display: flex;
    align-items: center;
    padding: 15px calc(0.75rem + 48px); // Extra indentation
    color: white;
    font-size: 16px;
    border-bottom: 1px solid transparentize($main, 0.9);
  
    &:last-child {
      border-bottom: none;
    }
  
    &:hover {
      background-color: darken($secondary, 10%);
    }
  }
  
  &__dropdownItemImage {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    object-fit: contain;
  }
  
  &__appPillContainer {
    margin-top: 2rem;
    margin-bottom: 6rem;
  }

  &__appPillLink{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 2rem;
    padding: 1px 10px;
    border: $tiicker-green 1px solid;
    color: white;
    width: 229px;
    height: 44px;
    margin: 0 auto;
   
  
    &:hover {
      color: $main;
    }
  }
  
  &__appIcons {
    display: flex;
    align-items: center;
    margin-right: 10px; // Add space between icons and text
  
    img {
      margin-right: 5px; // Add space between icons
  
      &:last-child {
        margin-right: 0; // Remove margin from the last icon
      }
    }
  }  
}


