@import "../../../../styles/theme.scss";

$xlarge-width: 1320px;
$large-width: 1140px;
$medium-width: 960px;
$small-width: 720px;
$xsmall-width: 540px;

$scrollbar-width: --scroll-width;

.containerOverride {
  overflow: hidden;
  max-width: 100%;

  &_Right {
    margin-right: 0;
    padding-right: 0;
  }

  &_RightTillSmall {
    margin-right: 0;
    padding-right: 0;

    @media (max-width: $small) {
      padding-left: 0;
      margin-left: 0;
    }
  }

  &_BothAtMedium {
    @media (max-width: $medium) {
      padding-left: 0;
      margin-left: 0;
      padding-right: 0;
      margin-right: 0;

      max-width: 100% !important;
      width: 100% !important;
    }
  }

  &_Left {
    margin-left: 0;
    padding-left: 0;
  }

  &_Both {
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
    max-width: 100% !important;
    width: 100% !important;
  }

  @media (min-width: $xsmall) {
    max-width: calc(
      ($xsmall-width + ((100vw - $xsmall-width) / 2)) - var($scrollbar-width, 0)
    );

    &_Right {
      margin-left: calc((100vw - $xsmall-width) / 2);
    }

    &_Left {
      margin-right: calc((100vw - $xsmall-width) / 2);
    }
  }

  @media (min-width: $small) {
    max-width: calc(
      ($small-width + ((100vw - $small-width) / 2)) - var($scrollbar-width, 0)
    );

    &_Right {
      margin-left: calc((100vw - $small-width) / 2);
    }

    &_Left {
      margin-right: calc((100vw - $small-width) / 2);
    }
  }

  @media (min-width: $medium) {
    max-width: calc(
      ($medium-width + ((100vw - $medium-width) / 2)) - var($scrollbar-width, 0)
    );

    &_Right {
      margin-left: calc((100vw - $medium-width) / 2);
    }

    &_RightTillSmall {
      margin-left: calc((100vw - $medium-width) / 2);
    }

    &_Left {
      margin-right: calc((100vw - $medium-width) / 2);
    }
  }

  @media (min-width: $large) {
    max-width: calc(
      ($large-width + ((100vw - $large-width) / 2)) - var($scrollbar-width, 0)
    );

    &_Right {
      margin-left: calc((100vw - $large-width) / 2);
    }

    &_RightTillSmall {
      margin-left: calc((100vw - $large-width) / 2);
    }

    &_Left {
      margin-right: calc((100vw - $large-width) / 2);
    }
  }

  @media (min-width: $xlarge) {
    max-width: calc(
      ($xlarge-width + ((100vw - $xlarge-width) / 2)) - var($scrollbar-width, 0)
    );

    &_Right {
      margin-left: calc((100vw - $xlarge-width) / 2);
    }

    &_RightTillSmall {
      margin-left: calc((100vw - $xlarge-width) / 2);
    }

    &_Left {
      margin-right: calc((100vw - $xlarge-width) / 2);
    }
  }
}
