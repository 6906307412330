.cover {
  position: fixed;
  background-color: black;
  opacity: 0.6;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  animation-duration: 0.3s;
  animation-name: fadein;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
}

.closing {
  animation-duration: 0.3s;
  animation-name: fadeout;
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}
