@import "../../styles/theme.scss";

.NotificationCallout {
  &__container {
    background-color: white;
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 500px;
    z-index: 100;
    box-shadow: 0 0 30px 0 rgba(9, 28, 55, 0.1);
  }

  &__header {
    color: #091c37;
    flex-grow: 0;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.1;
    text-align: left;
  }

  &__markAllAsRead {
    cursor: pointer;
    margin-left: auto;
  }

  &__subHeader {
    align-items: flex-end;
    background-color: #8cce65;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    gap: 8px;
    height: 16px;
    justify-content: center;
    padding: 4px 4px 3.5px;
    width: 16px;
  }

  &__title {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    gap: 8px;
    height: 22px;
    justify-content: flex-start;
    padding: 0;
    width: 142px;
  }

  &__topRow {
    align-items: center;
    align-self: stretch;
    border: solid 1px rgba(9, 28, 55, 0.1);
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    height: 62px;
    justify-content: space-between;
    padding: 20px 24px;
  }

  &__count {
    color: #091c37;
    flex-grow: 0;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 11px;
    letter-spacing: normal;
    line-height: 1.1;
    text-align: left;
    width: 6px;
  }

  &__mobile {
    &__container {
      background-color: white;
      position: absolute;
      left: 0;
      right: 0;
      width: 100vw;
      z-index: 100;
      margin-top: 20px;
    }

    &__topRow {
      align-items: center;
      align-self: stretch;
      border: solid 1px rgba(9, 28, 55, 0.1);
      justify-content: space-between;
      padding: 12px 20px;
    }

    &__title {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      gap: 8px;
      height: 22px;
      justify-content: flex-start;
      padding: 0;
      width: 142px;
    }

    &__header {
      color: #091c37;
      flex-grow: 0;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 1.1;
      text-align: left;
    }

    &__subHeader {
      align-items: flex-end;
      background-color: #8cce65;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      gap: 8px;
      height: 16px;
      justify-content: center;
      padding: 4px 4px 3.5px;
      width: 16px;
    }

    &__count {
      color: #091c37;
      flex-grow: 0;
      font-size: 10px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      height: 11px;
      letter-spacing: normal;
      line-height: 1.1;
      text-align: left;
      width: 6px;
    }

    &__markAllAsRead {
      cursor: pointer;
      font-size: 12px;
      padding-top: 10px;
    }

    &__titleRow {
      display: flex;
      justify-content: space-between;
    }

    &__calloutContainer {
      position: absolute;
      height: calc(100vh - 141.5px);
      width: 100vw;
      overflow: auto;
      overscroll-behavior: contain;
    }
  }
}

.hidden {
  display: none;
}
