@import "../../../../styles/theme.scss";

.container {
  position: relative;
  display: inline-block;
  width:100%;
}

.input {
  width: 100%;
  padding: 11.25px 24px;
  border: solid 1px $light-grey;
  background-color: transparent;
  transition: border-color 0.3s;
  font-size: 18px;
  color: grey;
  line-height: 1.75;
  outline: none;

  &::placeholder {
    color: transparent; // Hide the placeholder
  }

  &:hover,
  &:focus {
    border-color: $main;
  }

  &:autofill,
  &:-webkit-autofill,
  &:-moz-autofill,
  &:-moz-autofill-preview {
    background-color: #dceefe !important;
  }

  &_Slim {
    font-size: 16px;
    line-height: 1.2;
    padding: 11px 21px;
  }

  &_Inverse {
    color: white;

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }

  &_Error,
  &_Error:hover,
  &_Error:focus {
    border-color: $error;
  }

  &_Error {
    padding-right: 50px;
  }

  &_Error:before {
    content: "Something";
  }
}

.label {
  position: absolute;
  top: 25px; // Update initial top position
  left: 24px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 18px;
  color: grey;
  opacity: 0.7; // Set the initial opacity

  &_Focused {
    top: 0;
    font-size: 14px;
    color: $main;
    background-color: #dceefe;
    opacity: 1; // Set opacity when focused or filled
  }
}
